
// @import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
// @import '~@fortawesome/fontawesome-free/scss/solid.scss';
// @import '~@fortawesome/fontawesome-free/scss/regular.scss';
// @import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import "~bootstrap/dist/css/bootstrap.css";

@import "variables";
@import "mixins";
@import "theme";
@import "blog";

//@import "~aws-amplify-angular/theme.css";
// @import '~bootstrap/dist/css/bootstrap.min.css';

// removed from angular.json styles
//     "node_modules/angular-bootstrap-md/scss/bootstrap/bootstrap.scss",
//      "node_modules/angular-bootstrap-md/scss/mdb-free.scss",
// @import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,400,700);

.orange-cws {color: $orange-cws !important};
.orange-cws-300 {color: $orange-cws-300 !important};
.orange-cws-700 {color: $orange-cws-700 !important};

.blue-cws {color: $blue-cws !important};
.blue-cws-300 {color: $blue-cws-300 !important};
.blue-cws-700 { color: $blue-cws-700 !important};
.text-white { color: white !important;}

.bg-white {background-color: white !important};
.white-bk {background-color: white !important};

.orange-cws-bk {background-color: $orange-cws !important};
.orange-cws-300-bk {background-color: $orange-cws-300 !important};
.orange-cws-700-bk {background-color: $orange-cws-700 !important};

.blue-cws-bk {background-color: $blue-cws !important};
.blue-cws-300-bk {background-color: $blue-cws-300 !important};
.blue-cws-700-bk {background-color: $blue-cws-700 !important};

.orange-cws-fl { fill: $orange-cws !important};
.orange-cws-300-fl { fill: $orange-cws-300 !important};
.orange-cws-700-fl { fill: $orange-cws-700 !important};

.blue-cws-fl { fill: $blue-cws !important};
.blue-cws-300-fl { fill: $blue-cws-300 !important};
.blue-cws-700-fl { fill: $blue-cws-700 !important};

.base-color {
  background-color: #2e2e2e;
}

html, body {
    font-size: 100%;  // 1rem = 16px
    height: 100%;
}

hr {
  border: 1px solid #444;
}

#page {
  min-height: 100%;
}

#main {
  overflow: auto;
  padding-bottom: 224px;
}

#footer {
  position: relative;
  height:242px;
  margin-top: -242px;
}

a, a:visited, .cws-blue, .cws-blue:visited {
  color: #3db4e8 !important;
  text-decoration: none;
}

a:hover, .cws-blue:hover {
  color: lighten($color: #3db4e8 , $amount: 30%) !important;
  text-decoration: none;
}

.container-fluid
{
  padding-left: 0;
  padding-right: 0;
}

.pre-wrap {
  white-space: pre-wrap;
}

.green-text {
  color: green;
}

.red-text {
  color: red;
}

.grey-text {
  color: grey;
}

.btn-color-dark {
  background-color: #3e3e3e;
}

.table td {
  padding:16px !important;

}

